<template>
  <div class="userShow">
    <Nav></Nav>
    <div class="user-con">
      <!-- 签约记者 -->
      <!-- <div class="user-list">
        <div class="user-thr">
          <div class="ft-shadow"></div>
          <div class="user-name">摄影名家</div>
        </div>
        <div class="user-detail">
          <div class="user-reporter" v-for="k in userList_registed" :key="'nav_' + k.id">
            <div class="reporter-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onUserDetail(k.id, 3)"></div>
            <div class="reporter-con">
              <div class="name" @click="onUserDetail(k.id, 3)">-- {{ k.name }} --</div>
            </div>
          </div>
        </div>
        <div class="more" @click="onMorereporter(3)">更多...</div>
      </div> -->
      <!-- 摄影记者 -->
      <!-- <div class="user-list">
        <div class="user-sec">
          <div class="ft-shadow"></div>
          <div class="user-name">摄影记者</div>
        </div>
        <div class="user-detail">
          <div class="user-reporter" v-for="k in userList_reporter" :key="'nav_' + k.id">
            <div class="reporter-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onUserDetail(k.id, 2)"></div>
            <div class="reporter-con">
              <div class="name" @click="onUserDetail(k.id, 2)">-- {{ k.name }} --</div>
            </div>
          </div>
        </div>
        <div class="more" @click="onMorereporter(2)">更多...</div>
      </div> -->
      <!-- 特约摄影 -->
      <div class="user-list">
        <div class="user-title">
          <div class="ft-shadow"></div>
          <div class="user-name">特约摄影师</div>
          <div class="more" @click="onMorereporter(1)">更多...</div>
        </div>
        <div class="user-detail">
          <div class="user-reporter" v-for="k in userList_master" :key="'nav_' + k.id">
            <div class="reporter-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onUserDetail(k.id, 1)"></div>
            <div class="reporter-con">
              <div class="name" @click="onUserDetail(k.id, 1)">-- {{ k.name }} --</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      userList_master: [], // 摄影列表
      userList_reporter: [], // 摄影列表
      userList_registed: [], // 摄影列表
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      RD.userShow()
        .tops()
        .then((k) => {
          k.forEach((i) => {
            i.users.forEach((h) => {
              h.imgURL = RD.userShow().id(h.id).headPic_URL()
            })
          })
          for (var subList of k) {
            if (subList.code == 1) {
              this.userList_master = subList.users
            }
            if (subList.code == 2) {
              this.userList_reporter = subList.users
            }
            if (subList.code == 3) {
              this.userList_registed = subList.users
            }
          }
        })
    },
    // 更多
    onMorereporter(code) {
      this.$router.push({
        path: '/userShowList.html',
        query: { code: code },
      })
    },
    onUserDetail(id, code) {
      this.$router.push({
        path: '/userShowDetail.html',
        query: { id: id, code: code },
      })
    },
  },
}
</script>

<style scoped lang="less">
.userShow {
  height: auto;
  width: 100%;
  background-color: #fff;
}
@media all and (max-width: 800px) {
  .user-con {
    width: 750px;
    margin: 0 auto;
    .user-list {
      height: 360px;
      .user-title,
      .user-sec,
      .user-thr {
        text-align: left;
        height: 40px;
        margin-bottom: 20px;
        display: flex;
        .ft-shadow {
          margin-top: 1rem;
          background: no-repeat url(../assets/carame.png);
          background-size: cover;
          width: 40px;
          height: 40px;
        }
        .user-name {
          font-size: 18px;
          height: 55px;
          line-height: 55px;
        }
        .more {
          margin-left: 50px;
        }
      }
      .user-detail {
        height: 257px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        .user-reporter {
          width: 108px;
          height: 125px;
          overflow: hidden;
          margin-bottom: 2rem;
          margin-right: 2rem;
          position: relative;
          background-color: #f5f5f5;
          .reporter-img {
            width: 91px;
            height: 91px;
            margin: 0 auto;
            background: no-repeat 50% / cover;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
          }
          .reporter-con {
            height: 20px;
            text-align: center;
            .name {
              text-align: center;
              height: 20px;
              font-size: 14px;
              font-weight: bold;
              margin-top: 10px;
              cursor: pointer;
            }
          }
        }
      }
      .more {
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: right;
        font-size: 1.3rem;
        margin-right: 3rem;
        margin-top: 2rem;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .user-con {
    width: 120rem;
    margin: 0 auto;
    .user-list {
      height: 54rem;
      .user-title {
        text-align: left;
        height: 6rem;
        margin-bottom: 3rem;
        display: flex;
        .ft-shadow {
          margin-top: 1rem;
          background: no-repeat url(../assets/carame.png);
          background-size: cover;
          width: 5rem;
          height: 5rem;
        }
        .user-name {
          font-size: 1.8rem;
          height: 7rem;
          line-height: 7rem;
        }
      }
      .user-sec {
        text-align: left;
        height: 6rem;
        display: flex;
        margin-bottom: 3rem;
        .ft-shadow {
          margin-top: 1rem;
          background: no-repeat url(../assets/carame.png);
          background-size: cover;
          width: 5rem;
          height: 5rem;
        }
        .user-name {
          font-size: 1.8rem;
          height: 7rem;
          line-height: 7rem;
        }
      }
      .user-thr {
        text-align: left;
        height: 6rem;
        margin: 2rem 0rem;
        display: flex;
        .ft-shadow {
          margin-top: 1rem;
          background: no-repeat url(../assets/carame.png);
          background-size: cover;
          width: 5rem;
          height: 5rem;
        }
        .user-name {
          font-size: 1.8rem;
          height: 7rem;
          line-height: 7rem;
        }
      }
      .user-detail {
        height: 39.5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        .user-reporter {
          width: 17.8rem;
          height: 19rem;
          overflow: hidden;
          margin-bottom: 2rem;
          margin-right: 2rem;
          position: relative;
          background-color: #f5f5f5;
          .reporter-img {
            width: 15rem;
            height: 15rem;
            margin: 0 auto;
            background: no-repeat 50% / cover;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
          }
          .reporter-con {
            height: 3rem;
            text-align: center;
            .name {
              text-align: center;
              height: 3rem;
              font-size: 1.4rem;
              font-weight: bold;
              margin-top: 1rem;
              cursor: pointer;
            }
          }
        }
      }
      .more {
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: right;
        font-size: 1.3rem;
        margin-right: 3rem;
        margin-top: 2rem;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>
